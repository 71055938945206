// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hi-im-mark-index-js": () => import("./../../../src/pages/hi-im-mark/index.js" /* webpackChunkName: "component---src-pages-hi-im-mark-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-services-index-js": () => import("./../../../src/pages/legal-services/index.js" /* webpackChunkName: "component---src-pages-legal-services-index-js" */),
  "component---src-pages-our-practice-index-js": () => import("./../../../src/pages/our-practice/index.js" /* webpackChunkName: "component---src-pages-our-practice-index-js" */)
}

